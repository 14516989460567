import { createTheme } from "@material-ui/core/styles";

export const breakpoints = {
  xsmall: "(min-width: 0px)",
  small: "(min-width: 600px)",
  medium: "(min-width: 960px)",
  large: "(min-width: 1280px)",
  xlarge: "(min-width: 1920px)",
};

export const fonts = {
  xsmall: "0.75rem",
  small: "0.875rem",
  medium: "1rem",
  large: "1.125rem",
  light: "300",
  regular: "400",
  semibold: "500",
  bold: "700",
};

// https://colornamer.netlify.com/ to create a unique name
// main flat colors palette used : https://flatuicolors.com/palette/gb
export const palette = {
  white: "#FFFFFF",
  lynxWhite: "#f5f6fa",
  insignaWhite: "#EDF5F9",
  crystalFallsGray: "#E0E6F5",
  hintOfPensiveGrey: "#dcdde1",
  ancestralWaterGrey: "#D0D0D0",
  weatheredStoneGrey: "#C4C4C4",
  charmedChaliceGrey: "#A1A1A1",
  blueBerrySodaGrey: "#7f8fa6",
  electromagneticGrey: "#2f3640",
  goshawkGrey: "#444444",
  black: "#000000",
  salmonberryYellow: "#FFF57B",
  cheeseOrange: "#FFA600",
  nanohanachaGoldYellow: "#e1b12c",
  alpacaWoolOrange: "#FBECE1",
  peachadeOrange: "#FBDEC8",
  harleyDavidsonOrange: "#c23616",
  promPink: "#DEA4AD",
  aquaSpringGreen: "#E9F3E9",
  lightKatsuraGreen: "#D7EBD8",
  chenilleGreen: "#A6CF8E",
  skirretGreen: "#44bd32",
  vidaLocaGreen: "#4EA01E",
  advantageousGreen: "#19766B",
  shovelKnightBlue: "#37CAF5",
  spanishBlue: "#046AB4",
  starOfLifeBlue: "#077BC2",
  picoVoidBlue: "#192a56",
  mazarineBlue: "#273c75",
  seaBrookBlue: "#487eb0",
  dunMoroghBlue: "#81B4D9",
  mexicanMudkipPink: "#FDD8DE",
  softPink: "#FBB1BC",
  lavenderSaverPurple: "#EAD7FE",
  neonBoneyardPurple: "#E0C3FE",
  amygdalaPurple: "#6C0F59",
  voluptuousVioletPurple: "#6F04DC",
  bone: "#DDD8C4",
  celadon: "#C6BD9A",
  cambridgeBlue: "#84B59F",
  emeraldOasis: "#69A297",
  aireForceBlue: "#50808E",
  khaki: "#A0997A",
};

export const colors = {
  primary: palette.picoVoidBlue,
  secondary: palette.mazarineBlue,
  accent: palette.harleyDavidsonOrange,
  text: palette.electromagneticGrey,
  altText: {
    contrast: palette.lynxWhite,
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },
  background: palette.hintOfPensiveGrey,
  paper: palette.white,
  divider: "rgba(0, 0, 0, 0.12)",
  error: palette.harleyDavidsonOrange,
  warning: palette.nanohanachaGoldYellow,
  partnerSpecificity: {
    online: palette.advantageousGreen,
    offline: palette.amygdalaPurple,
  },
  partnerTexts: {
    shortDescription: palette.vidaLocaGreen,
    description: palette.advantageousGreen,
    conditions: palette.mazarineBlue,
    customerServiceComment: palette.amygdalaPurple,
  },
  offer: {
    cashbackissime: palette.mexicanMudkipPink,
  },
  exclusivePartnerScheduling: {
    schedulable: palette.nanohanachaGoldYellow,
    scheduled: palette.skirretGreen,
  },
  row: {
    alt: palette.crystalFallsGray,
    green: palette.aquaSpringGreen,
    altGreen: palette.lightKatsuraGreen,
    orange: palette.alpacaWoolOrange,
    altOrange: palette.peachadeOrange,
    pink: palette.mexicanMudkipPink,
    altPink: palette.softPink,
    purple: palette.lavenderSaverPurple,
    altPurple: palette.neonBoneyardPurple,
  },
  faqs: {
    deleted: palette.harleyDavidsonOrange,
    disabled: palette.goshawkGrey,
  },
  purchaseIndicators: {
    duplicateColor: palette.salmonberryYellow,
    pendingColor: palette.promPink,
  },
  saveButton: palette.skirretGreen,
  cancelButton: palette.blueBerrySodaGrey,
  dashboard: {
    online: {
      main: palette.spanishBlue,
      secondary: palette.dunMoroghBlue,
      tertiary: palette.weatheredStoneGrey,
    },
    offline: {
      main: palette.vidaLocaGreen,
      secondary: palette.chenilleGreen,
      tertiary: palette.weatheredStoneGrey,
    },
    ebon: {
      main: palette.cheeseOrange,
    },
    greys: {
      main: palette.goshawkGrey,
      secondary: palette.charmedChaliceGrey,
      tertiary: palette.ancestralWaterGrey,
    },
    budget: {
      main: palette.harleyDavidsonOrange,
    },
  },
  dailyNews: {
    online: palette.starOfLifeBlue,
    offline: palette.shovelKnightBlue,
    ebon: palette.voluptuousVioletPurple,
  },
  platformUrl: {
    temporaryColor: palette.lightKatsuraGreen,
    duplicateColor: palette.harleyDavidsonOrange,
  },
  coupon: {
    discountColor: palette.harleyDavidsonOrange,
    deliveryColor: palette.vidaLocaGreen,
    giftColor: palette.starOfLifeBlue,
    minimumQuantityDiscountColor: palette.cheeseOrange,
    otherColor: palette.aireForceBlue,
  },
  dailyRevShare: {
    member: palette.cheeseOrange,
    commission: palette.spanishBlue,
  },
  partnerSEOFaq: {
    delete: palette.harleyDavidsonOrange,
    disable: palette.goshawkGrey,
  },
  ebonPartner: {
    notConfigured: palette.weatheredStoneGrey,
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.altText.contrast,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.altText.contrast,
    },
    validation: {
      main: colors.saveButton,
      contrastText: colors.altText.contrast,
    },
    text: {
      primary: colors.text,
      secondary: colors.altText.secondary,
      disabled: colors.altText.disabled,
      hint: colors.altText.hint,
    },
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    divider: colors.divider,
    error: {
      main: palette.harleyDavidsonOrange,
      contrastText: colors.altText.contrast,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
      },
    },
  },
});
