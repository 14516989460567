/* eslint-disable no-console */

const { localStorage } = window;
const get = key => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error("Unexpected error thrown when GET item in local storage", e);
    return null;
  }
};

const getJSON = key => JSON.parse(get(key));

const set = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error("Unexpected error thrown when SET item in local storage", e);
  }
};

const setJSON = (key, value) => set(key, JSON.stringify(value));

const remove = key => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(
      "Unexpected error thrown when REMOVE item in local storage",
      e
    );
  }
};

const clear = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.error(
      "Unexpected error thrown when CLEAR items in local storage",
      e
    );
  }
};

export default {
  get,
  set,
  getJSON,
  setJSON,
  remove,
  clear,
};
