import { lazy, Suspense, useRef } from "react";

import { Router } from "@reach/router";
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";
import { Button, LinearProgress } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";

// Resources
import { muiTheme } from "../styles/theme";
import { PMI, PMI_GET } from "../constants/roles";
import { OFFLINE_ACTIVITY, ONLINE_ACTIVITY } from "../constants/cashback";

// Components
import GlobalStyle from "../styles/global";
import RedirectRoute from "../components/RedirectRoute";
import PartnerProvider from "../context/partner/PartnerProvider";
import { GlobalDataProvider } from "../utils/hooks/useGlobalData";
import { ApplicationProvider } from "../utils/hooks/useApplication";
import { UserConsumer, UserProvider } from "../utils/hooks/useUser";
import { ConfirmDialogProvider } from "../utils/hooks/useConfirmDialog";
// Lazy components
const Layout = lazy(() => import("../components/Layout"));
const MultiApplication = lazy(() => import("../components/MultiApplication"));

// Pages
const DailyNewsCurrentSelection = lazy(() =>
  import("./DailyNewsCurrentSelection")
);
const ApplicationClientRequest = lazy(() =>
  import("./ApplicationClientRequest")
);
const CloseCustomerServiceMemberRequest = lazy(() =>
  import("./CloseCustomerServiceMemberRequest")
);
const PartnerExclusiveScheduling = lazy(() =>
  import("./PartnerExclusiveScheduling")
);
const EbonOrderCreation = lazy(() => import("./EbonOrderCreation"));
const CompetitiveIntelligence = lazy(() =>
  import("./PartnerMode/CompetitiveIntelligence")
);
const Mea = lazy(() => import("./Mea"));
const Home = lazy(() => import("./Home"));
const Ebon = lazy(() => import("./Ebon"));
const Login = lazy(() => import("./Login"));
const Export = lazy(() => import("./Export"));
const Member = lazy(() => import("./Member"));
const Backlog = lazy(() => import("./Backlog"));
const Tracking = lazy(() => import("./Tracking"));
const NotFound = lazy(() => import("./NotFound"));
const Purchase = lazy(() => import("./Purchase"));
const Dashboard = lazy(() => import("./Dashboard"));
const EbonOrder = lazy(() => import("./EbonOrder"));
const EbonGlobal = lazy(() => import("./EbonGlobal"));
const Offer = lazy(() => import("./PartnerMode/Offer"));
const AddPurchase = lazy(() => import("./AddPurchase"));
const PictureUpload = lazy(() => import("./GenericPictureUpload"));
const BolPlatformPurchaseImport = lazy(() =>
  import("./BolPlatformPurchaseImport")
);
const CashbackRate = lazy(() => import("./CashbackRate"));
const EbonApiStock = lazy(() => import("./EbonApiStock"));
const PlatformClaim = lazy(() => import("./PlatformClaim"));
const Communication = lazy(() => import("./Communication"));
const OnlinePartner = lazy(() => import("./OnlinePartner"));
const PasswordUpdate = lazy(() => import("./PasswordUpdate"));
const OfflinePartner = lazy(() => import("./OfflinePartner"));
const PaymentRequest = lazy(() => import("./PaymentRequest"));
const CommissionBoost = lazy(() => import("./CommissionBoost"));
const FlashCashHistory = lazy(() => import("./FlashcashHistory"));
const DailyNewsGallery = lazy(() => import("./DailyNewsGallery"));
const CatalinaReward = lazy(() => import("./CatalinaReward"));
const PurchaseMassUpdate = lazy(() => import("./PurchaseMassUpdate"));
const PartnerGlobal = lazy(() => import("./PartnerMode/PartnerGlobal"));
const PartnerNameMatching = lazy(() => import("./PartnerNameMatching"));
const ToolbarNotification = lazy(() => import("./ToolbarNotification"));
const DailyNewsPartnerTop = lazy(() => import("./DailyNewsPartnerTop"));
const OnlinePartnerCreation = lazy(() => import("./OnlinePartnerCreation"));
const AccountingInformation = lazy(() => import("./AccountingInformation"));
const MeaMultipleImageUpdate = lazy(() => import("./MeaMultipleImageUpdate"));
const OfflinePartnerCreation = lazy(() => import("./OfflinePartnerCreation"));
const EbuyclubOfferComparison = lazy(() => import("./EbuyclubOfferComparison"));
const EbonGroupAdministration = lazy(() => import("./EbonGroupAdministration"));
const Coupon = lazy(() => import("./Coupon"));
const Gallery = lazy(() => import("./Gallery"));
const EbonAdministration = lazy(() => import("./Ebon/Administration"));
const Faqs = lazy(() => import("./Faqs"));
const JackpotNotifications = lazy(() => import("./JackpotNotifications"));
const Invoicing = lazy(() => import("./Ebon/Invoicing"));
const ECard = lazy(() => import("./Ebon/ECard"));
const EbonBatchOrder = lazy(() => import("./Ebon/EbonBatchOrder"));
const PaymentRequestValidation = lazy(() =>
  import("./PaymentRequestValidation")
);
const AlertRecipient = lazy(() => import("./AlertRecipient"));
const Review = lazy(() => import("./Review"));

export default () => {
  const notistackRef = useRef();

  const handleSnackbarDismiss = key => () =>
    notistackRef.current.closeSnackbar(key);

  return (
    <>
      <GlobalStyle />

      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            action={key => (
              <Button color="inherit" onClick={handleSnackbarDismiss(key)}>
                Fermer
              </Button>
            )}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <ConfirmDialogProvider>
                <ApplicationProvider>
                  <UserProvider>
                    <GlobalDataProvider>
                      <PartnerProvider activityType={ONLINE_ACTIVITY}>
                        <PartnerProvider activityType={OFFLINE_ACTIVITY}>
                          <UserConsumer>
                            {({
                              user,
                              landingPath,
                              temporaryUser,
                              finishedAuthInit,
                              hasOneOfRequiredRoles,
                            }) => (
                              <Suspense
                                fallback={<LinearProgress color="primary" />}
                              >
                                {finishedAuthInit && (
                                  <Router id="router">
                                    {user && (
                                      <Layout path="/">
                                        <Home path="/" />
                                        <Mea path="mea/*" />
                                        <Ebon path="ebon/*" />
                                        <Export path="export" />
                                        <Backlog path="backlog" />
                                        <Member path="member/*" />
                                        <Tracking path="tracking" />
                                        <Purchase path="purchase" />
                                        <Dashboard path="dashboard" />
                                        <EbonOrder path="ebon-order" />
                                        <AddPurchase path="add-purchase" />
                                        <CashbackRate path="cashback-rate" />
                                        <PlatformClaim path="platform-claim" />
                                        <PictureUpload path="picture-upload" />
                                        <BolPlatformPurchaseImport path="bol-platform-purchase-import" />
                                        <Communication path="communication/*" />
                                        <PaymentRequest path="payment-request" />
                                        <PaymentRequestValidation path="payment-request-validation" />
                                        <OnlinePartner path="partner/online/*" />
                                        <OfflinePartner path="partner/offline/*" />
                                        <FlashCashHistory path="flashcash-history" />
                                        <DailyNewsGallery path="daily-news/gallery" />
                                        <EbonOrderCreation path="ebon-order-creation/*" />
                                        <PurchaseMassUpdate path="purchase-mass-update" />
                                        <JackpotNotifications path="jackpot-ebon-api-notification" />
                                        <DailyNewsPartnerTop path="daily-news/partner-top" />
                                        <ToolbarNotification path="toolbar/notification/*" />
                                        <AccountingInformation path="accounting-information/*" />
                                        <OfflinePartnerCreation path="offline-partner-creation" />
                                        <EbuyclubOfferComparison path="offer/ebuyclub-comparison" />
                                        <ApplicationClientRequest path="application-client-request" />
                                        <DailyNewsCurrentSelection path="daily-news/current-selection" />
                                        <PartnerExclusiveScheduling path="partner-exclusive-scheduling/*" />
                                        <CloseCustomerServiceMemberRequest path="close-customer-service-member-request" />
                                        <CatalinaReward path="catalina-reward" />
                                        <PartnerNameMatching path="/partner-name-matching" />
                                        <Gallery path="gallery" />
                                        <Gallery path="gallery/partner" />
                                        <EbonAdministration path="ebon-administration" />
                                        <Faqs path="faqs" />
                                        <Invoicing path="invoicing" />
                                        <ECard path="ebon-order-creation-ebuycard" />
                                        <EbonBatchOrder path="ebon-batch-order" />
                                        <AlertRecipient path="alert-recipient" />
                                        <Review path="review" />
                                        <MultiApplication path="multi">
                                          <Home path="/" />
                                          <Offer path="offer/*" />
                                          <PartnerGlobal path="partner/*" />

                                          <PlatformClaim path="platform-claim" />
                                          <EbonApiStock path="ebon-api-stock/*" />
                                          <EbonGroupAdministration path="ebon/*" />

                                          <CommissionBoost path="commission-boost" />
                                          <EbonGlobal path="ebon-global/*" />
                                          <OnlinePartnerCreation path="partner-creation/*" />
                                          <CompetitiveIntelligence path="competitor/*" />
                                          <MeaMultipleImageUpdate path="mea-image-update" />
                                          <Coupon path="coupon" />
                                        </MultiApplication>

                                        <NotFound path="404" />

                                        <RedirectRoute
                                          path="login"
                                          to={
                                            landingPath === "/" &&
                                            hasOneOfRequiredRoles([
                                              PMI,
                                              PMI_GET,
                                            ]) &&
                                            !user.applicationIds.includes(-1)
                                              ? "/dashboard"
                                              : landingPath
                                          }
                                        />

                                        <RedirectRoute
                                          path="password-update"
                                          to="/login"
                                        />

                                        <RedirectRoute to="/404" default />
                                      </Layout>
                                    )}

                                    {temporaryUser && (
                                      <>
                                        <PasswordUpdate path="password-update" />

                                        <RedirectRoute
                                          to="/password-update"
                                          default
                                        />
                                      </>
                                    )}

                                    {!user && !temporaryUser && (
                                      <>
                                        <Login path="login" />

                                        <RedirectRoute to="/login" default />
                                      </>
                                    )}
                                  </Router>
                                )}
                              </Suspense>
                            )}
                          </UserConsumer>
                        </PartnerProvider>
                      </PartnerProvider>
                    </GlobalDataProvider>
                  </UserProvider>
                </ApplicationProvider>
              </ConfirmDialogProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};
