import jwtDecode from "jwt-decode";

export const getAuthInfos = token => {
  if (token) {
    const {
      exp,
      username,
      applicationIds = [],
      roles = [],
      needsPasswordUpdate = false,
      passwordPolicyId,
    } = jwtDecode(token);
    const isAuthenticated = exp * 1000 > Date.now();

    return {
      isAuthenticated,
      username,
      applicationIds,
      roles,
      needsPasswordUpdate,
      passwordPolicyId,
    };
  }

  return { isAuthenticated: false };
};
