import axios from "axios";

import Storage from "./storage";
import { DEBUG } from "../constants";
import { API, GET } from "../constants/api";
import { AUTH_TOKEN } from "../constants/storage";

const formatParams = params =>
  params
    ? Object.keys(params).reduce(
        (acc, key) => ({
          ...acc,
          [key]: Array.isArray(params[key])
            ? params[key].join(",")
            : params[key],
        }),
        {}
      )
    : null;

const formatResponse = ({ status, data = {}, config = {} } = {}) => {
  if (data instanceof ArrayBuffer) {
    return { data };
  }
  if (
    config.method.toUpperCase() !== GET ||
    data instanceof Object ||
    status !== 200
  ) {
    return data || {};
  }
  // eslint-disable-next-line no-console
  if (DEBUG) console.error("[API] UNEXPECTED_SERVER_RESPONSE", status, data);
  return { ok: false, message: "UNEXPECTED_SERVER_RESPONSE" };
};

const getResponseType = ({ isFile, isCsv }) => {
  if (isCsv) {
    return "text";
  }

  if (isFile) {
    return "blob";
  }

  return "json";
};

/**
 *  Response Schema
 *  {
 *    // `data` is the response that was provided by the server
 *    data: {
 *      payload: any,     // Data
 *      message: string,  // Error message key
 *      metadata: object,
 *      paging: object,
 *    },
 *
 *    // `status` is the HTTP status code from the server response
 *    status: 200,
 *
 *    // `statusText` is the HTTP status message from the server response
 *    statusText: 'OK',
 *
 *    // `headers` the headers that the server responded with
 *    // All header names are lower cased
 *    headers: {},
 *
 *    // `config` is the config that was provided to `axios` for the request
 *    config: {},
 *
 *    // `request` is the request that generated this response
 *    // It is the last ClientRequest instance in node.js (in redirects)
 *    // and an XMLHttpRequest instance the browser
 *    request: {}
 *  }
 */

export default async ({
  method = GET,
  endpoint,
  url,
  params,
  noAuth,
  headers,
  isFile = false,
  isCsv = false,
  withCredentials,
}) => {
  if (!method) {
    throw new Error("[API] Api request made with no METHOD");
  }
  if (!endpoint && !url) {
    throw new Error("[API] Api request made with no ENDPOINT or URL");
  }

  const token = Storage.getJSON(AUTH_TOKEN);
  const authorization =
    noAuth || !token ? {} : { Authorization: `Bearer ${token}` };
  const dataOrParams =
    method === GET ? { params: formatParams(params) } : { data: params };

  const responseType = getResponseType({ isFile, isCsv });

  return axios({
    responseType,
    headers: {
      "Content-Type": "application/json",
      ...authorization,
      ...headers,
    },
    method,
    baseURL: API,
    url: endpoint || url,
    withCredentials,
    ...dataOrParams,
  })
    .then(response => {
      const basicReponse = {
        ok: true,
        status: (response || {}).status,
      };

      return isFile || isCsv
        ? { ...basicReponse, ...response }
        : { ...basicReponse, ...formatResponse(response) };
    })
    .catch(async error => {
      const { response, request } = error;
      const { status } = response || {};

      if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (status === 401 /* UNAUTHORIZED */ || status === 403) {
          return {
            status,
            error: "UNAUTHORIZED",
            ...formatResponse(response),
          };
        }

        return {
          status,
          error: "SERVER_ERROR",
          ...formatResponse(response),
        };
      }

      if (request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
          status: 503 /* SERVICE_UNAVAILABLE */,
          error: "SERVICE_UNAVAILABLE",
        };
      }

      // Something happened in setting up the request that triggered an Error
      return {
        status: 418 /* I’M A TEAPOT */,
        error: "INTERNAL_ERROR",
      };
    });
};
