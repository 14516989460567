import React, { createContext, useContext } from "react";

import useApi from "./useApi";
import {
  PLATFORM,
  PLATFORM_GET,
  SALES_REPRESENTATIVE,
  SALES_REPRESENTATIVE_GET,
} from "../../constants/roles";
import useUser from "./useUser";
import useDidUpdate from "./useDidUpdate";
import { NOT_REQUESTED } from "../../constants/api";

const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
  const { user, hasOneOfRequiredRoles } = useUser();
  const {
    state: salesRepresentatives,
    setParams: setSalesRepresentativeParams,
  } = useApi("/sales-representative", {}, { waitFirstCall: true });

  const { state: platforms, setParams: setPlatformParams } = useApi(
    "/platform",
    {},
    { waitFirstCall: true }
  );

  const { state: languages, setParams: setLanguageParams } = useApi(
    "/master/language",
    {},
    { waitFirstCall: true }
  );

  useDidUpdate(() => {
    if (
      hasOneOfRequiredRoles([SALES_REPRESENTATIVE, SALES_REPRESENTATIVE_GET]) &&
      salesRepresentatives.status === NOT_REQUESTED
    ) {
      setSalesRepresentativeParams({});
    }
    if (
      hasOneOfRequiredRoles([PLATFORM, PLATFORM_GET]) &&
      platforms.status === NOT_REQUESTED
    ) {
      setPlatformParams({});
    }
    if (languages.status === NOT_REQUESTED) {
      setLanguageParams({});
    }
  }, [user]);

  return (
    <GlobalDataContext.Provider
      value={{
        salesRepresentatives,
        platforms,
        languages,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);

export default useGlobalData;

export const GlobalDataConsumer = GlobalDataContext.Consumer;
