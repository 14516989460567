import React from "react";
import { Global } from "@emotion/react";

import { colors, fonts } from "./theme";

const styles = `
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: ${fonts.medium};
    line-height: 1.5;
    color: ${colors.text};
    background: ${colors.background};
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  a {
    color: inherit;
    cursor: pointer;

    @:hover, @:focus, @:visited {
      color: inherit;
    }
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, h7, h8, h9 {
    margin: 0;
  }
`;

export default () => <Global styles={styles} />;
