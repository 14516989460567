import { createContext, useContext, useEffect, useState } from "react";

import { navigate } from "@reach/router";

import Storage from "../storage";
import useDidUpdate from "./useDidUpdate";
import { getAuthInfos } from "../jwtParser";
import { SUPER } from "../../constants/roles";
import useApplication from "./useApplication";
import { FAILURE } from "../../constants/api";
import { AUTH_TOKEN } from "../../constants/storage";
import {
  ALL_APPLICATIONS_ID,
  MULTI_APPLICATION_IDS,
} from "../../constants/application";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [landingPath, setLandingPath] = useState(null);
  const [user, setUser] = useState(null);
  const [temporaryUser, setTemporaryUser] = useState(null);
  const [finishedAuthInit, setFinishedAuthInit] = useState(false);
  const {
    applications,
    handleApplicationChange,
    fetchApplications,
    handleUserAuthenticatedChange,
  } = useApplication();

  const hasSpecificRequiredRole = requiredRole =>
    !!user && user.roles.includes(requiredRole);

  const hasAllRequiredRoles = requiredRoles =>
    !!user &&
    (user.roles.includes(SUPER) ||
      requiredRoles.every(hasSpecificRequiredRole));

  const hasOneOfRequiredRoles = requiredRoles =>
    !!user &&
    (user.roles.includes(SUPER) ||
      !!requiredRoles.find(hasSpecificRequiredRole));

  const hasMultiApplicationAccess =
    !!user &&
    (user.applicationIds.length > 1 ||
      user.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id)));

  const hasSpecificApplicationAccess = applicationId =>
    !!user &&
    (user.applicationIds.includes(applicationId) || hasAllApplicationAccess);

  const hasAllApplicationAccess =
    !!user && user.applicationIds.includes(ALL_APPLICATIONS_ID);

  useDidUpdate(() => {
    if (
      applications.status === FAILURE &&
      applications.error === "UNAUTHORIZED"
    ) {
      handleDisconnection();
    }
  }, [applications.status]);

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      setLandingPath(window.location.pathname + window.location.search);
    } else {
      setLandingPath("/");
    }

    if (user === null) {
      const token = Storage.getJSON(AUTH_TOKEN);
      const infos = getAuthInfos(token);
      // set if can be loaded from the storage
      if (infos.isAuthenticated && !infos.needsPasswordUpdate) {
        fetchApplications();
        if (
          infos.applicationIds.length === 1 &&
          !infos.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id))
        ) {
          handleApplicationChange(infos.applicationIds[0]);
        }
        setUser(infos);
      } else {
        navigate("/login");
      }
    }

    setFinishedAuthInit(true);
  }, []);

  useDidUpdate(() => {
    handleUserAuthenticatedChange(!!user?.isAuthenticated);
  }, [user]);

  const handleConnection = token => {
    const infos = getAuthInfos(token);

    if (infos.isAuthenticated) {
      Storage.setJSON(AUTH_TOKEN, token);
      if (!infos.needsPasswordUpdate) {
        setTemporaryUser(null);
        fetchApplications();
        if (
          infos.applicationIds.length === 1 &&
          !infos.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id))
        ) {
          handleApplicationChange(infos.applicationIds[0]);
        }
        setUser(infos);
      } else {
        setTemporaryUser(infos);
      }
    }
  };

  const handleDisconnection = () => {
    Storage.remove(AUTH_TOKEN);
    setUser(null);
    handleApplicationChange(null);
    setLandingPath("/");
  };

  return (
    <UserContext.Provider
      value={{
        user,
        landingPath,
        temporaryUser,
        finishedAuthInit,
        handleDisconnection,
        handleConnection,
        hasAllRequiredRoles,
        hasOneOfRequiredRoles,
        hasMultiApplicationAccess,
        hasAllApplicationAccess,
        hasSpecificRequiredRole,
        hasSpecificApplicationAccess,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export default useUser;

export const UserConsumer = UserContext.Consumer;
