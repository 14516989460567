import { useState, useEffect } from "react";

import api from "../api";
import { NOT_REQUESTED, LOADING, SUCCESS, FAILURE } from "../../constants/api";

const useApi = (initialEndpoint, initialParams, config) => {
  const { method, noAuth, headers, responseIsNotAnArray, waitFirstCall } =
    config || {};

  const [loaded, setLoaded] = useState(!waitFirstCall);
  const defaultState = {
    status: NOT_REQUESTED,
    data: responseIsNotAnArray ? {} : [],
    paging: responseIsNotAnArray ? null : {},
    error: null,
  };
  const [state, setState] = useState(defaultState);
  const [endpoint, setEndpoint] = useState(initialEndpoint || "");
  const [params, setParams] = useState(initialParams || {});

  const reload = () => {
    setParams({ ...params });
  };

  const reset = () => {
    setState({ ...defaultState });
  };

  useEffect(() => {
    let didCancel = false;

    if (waitFirstCall && !loaded) {
      setLoaded(true);
    }

    if (loaded && endpoint) {
      (async () => {
        setState({ ...state, status: LOADING });

        await api({
          endpoint,
          headers,
          method,
          params,
          noAuth,
        }).then(
          response => {
            if (!didCancel) {
              const { ok, payload, error } = response;

              if (ok) {
                setState({
                  ...state,
                  status: SUCCESS,
                  data: payload,
                  paging: !responseIsNotAnArray
                    ? response.paging
                    : state.paging,
                  error: defaultState.error,
                });
              } else {
                setState({
                  ...state,
                  data: defaultState.data,
                  paging: defaultState.paging,
                  status: FAILURE,
                  error,
                });
              }
            }
          },
          reason => {
            setState({
              ...state,
              status: FAILURE,
              error: reason,
            });
          }
        );
      })();
    }

    return () => {
      didCancel = true;
    };
  }, [params, endpoint]); // eslint-disable-line react-hooks/exhaustive-deps
  /* We only want the api call to be redone when new params arrive (like pagination change) */

  return {
    state,
    setParams,
    setEndpoint,
    reset,
    reload,
    params,
    endpoint,
  };
};

export default useApi;
